const moduleGeneral = {
  state: () => ({
    allFeatures: null,
    singleFeature: null,
  }),
  actions: {
    async getAllFeatures(context, payload) {
      try {
        await this.$axios
          .get(`/home-page/home-features`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setAllFeatures", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleFeature(context, payload) {
      // console.log(payload)
      try {
        await this.$axios
          .get(`/home-page/home-features/${payload}`)
          .then((response) => {
            context.commit("setSingleFeature", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setAllFeatures(state, payload) {
      state.allFeatures = payload;
    },
    setSingleFeature(state, payload) {
      state.singleFeature = payload;
    },
  },
  getters: {},
};

export default moduleGeneral;
