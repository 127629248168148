const moduleGeneral = {
  state: () => ({
    posts: null,
    currentPost: null,
    allSectors: null,
    server: null,
    dashboardServer : null,
    breadCrumb: {
      title: null,
      crud: [],
    },
    toggleDrawer: false,
    allProfitDistributionPolicy: []
  }),
  actions: {
    async getPosts(context, payload) {
      const server = context.rootState.general.server;
      try {
        await this.$axios
          .get(
            `${server}/home/company-blogs?per_page=${payload.per_page}&page=${payload.page}`
          )
          .then((response) => {
            context.commit("totalPosts", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getPostsByID(context, payload) {
      try {
        await this.$axios
          .get("https://dummyjson.com/posts/" + payload.id)
          .then((response) => {
            context.commit("currentPostMutation", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllSectors(context, payload) {
      const server = context.rootState.general.server;
      try {
        await this.$axios
          .get(`${server}/market-summary/sectors`)
          .then((response) => {
            context.commit("setAllSectors", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    ToggleDrawerHandler(context) {
      context.commit("setToggleDrawer");
    },
    async getProfitDistributionPolicy(context , payload){
      try {
        await this.$axios
          .get(`https://newmo.neoxero.com/api/v1/profit-distribution/policy`)
          .then((response) => {
            context.commit("setProfitDistributionPolicy", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    }
  },
  mutations: {
    totalPosts(state, payload) {
      state.posts = payload;
    },
    currentPostMutation(state, data) {
      return (state.currentPost = data);
    },
    setServer(state) {
      state.server = 'https://newmo.neoxero.com/api/v1';
      state.dashboardServer = this.$config.baseServerUrl
    },
    setAllSectors(state, payload) {
      state.allSectors = payload;
    },
    setBreadCrumb(state, payload) {
      state.breadCrumb = payload;
    },
    setToggleDrawer(state) {
      return (state.toggleDrawer = !state.toggleDrawer);
    },
    setProfitDistributionPolicy(state, payload){
      state.allProfitDistributionPolicy = payload;
    }
  },
  getters: {},
};

export default moduleGeneral;
