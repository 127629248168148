const moduleCalendar = {
  state: () => ({
    companiesSubscriptions: null,
    singleCompaniesSubscription: null,
    cashDistributions: null,
    singleCashDistribution: null,
    singleProfitResults: null,
    profitResults: null
  }),
  actions: {
    async getCompaniesSubscriptions(context, payload) {
      try {
        await this.$axios
          .get(`/companies-subscriptions` , {
            params:{
              per_page: payload.perPage,
              page: payload.page,
              search: payload.search,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setCompaniesSubscriptions", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleCompaniesSubscription(context, payload) {
      try {
        await this.$axios
          .get(`/companies-subscriptions/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleCompaniesSubscription", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCashDistributions(context, payload) {
      try {
        await this.$axios
          .get(`/cash-distribution`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              search: payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setCashDistributions", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleCashDistribution(context, payload) {
      try {
        await this.$axios
          .get(`/cash-distribution/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleCashDistribution", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getProfitResults(context, payload) {
      try {
        await this.$axios
          .get(`/profit-results` , {
            params:{
              per_page: payload.perPage,
              page: payload.page,
              search: payload.search,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setProfitResults", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleProfitResults(context, payload) {
      try {
        await this.$axios
          .get(`/profit-results/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleProfitResults", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },

  },
  mutations: {
    setCompaniesSubscriptions(state, payload) {
      state.companiesSubscriptions = payload
    },
    setSingleCompaniesSubscription(state, payload) {
      state.singleCompaniesSubscription = payload
    },
    setSingleCashDistribution(state, payload) {
      state.singleCashDistribution = payload;
    },
    setCashDistributions(state, payload) {
      state.cashDistributions = payload;
    },
    setSingleProfitResults(state, payload) {
      state.singleProfitResults = payload;
    },
    setProfitResults(state, payload) {
      state.profitResults = payload;
    },
  },
  getters: {},
};

export default moduleCalendar;
