const moduleGeneral = {
  state: () => ({
    logData: [],
  }),
  actions: {
    async getLogData(context, payload) {
      try {
        await this.$axios
          .get(`/login-activity`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              'filters[search]': payload.search,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setLogData", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },

  },
  mutations: {

    setLogData(state, payload) {
      state.logData = payload
    },

  },
  getters: {},
};

export default moduleGeneral;




