const moduleAdmins = {
  state: () => ({
    AllAdminUsers: [],
    SingleAdminUser: null,
    allRoles: null,
    singleRole: null,
    defaultPermissions: null
  }),
  actions: {
    async getAllAdminUsers(context, payload) {
      try {
        await this.$axios
          .get(`/admin-users`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              "filters[search]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setAllAdminUsers", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleAdminUser(context, payload) {
      try {
        await this.$axios
          .get(`/admin-users/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleAdminUser", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllRoles(context, payload) {
      try {
        await this.$axios
          .get(`/roles`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
            },
          })
          .then((response) => {
            context.commit("setAllRoles", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleRole(context, payload) {
      try {
        await this.$axios
          .get(`/roles/${payload}`)
          .then((response) => {
            context.commit("setSingleRole", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getDefaultPermissions(context, payload) {
      try {
        await this.$axios
          .get(`/permissions`)
          .then((response) => {
            context.commit("setDefaultPermissions", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setAllAdminUsers(state, payload) {
      state.AllAdminUsers = payload;
    },
    setSingleAdminUser(state, payload) {
      state.SingleAdminUser = payload;
    },
    setAllRoles(state, payload) {
      state.allRoles = payload;
    },
    setSingleRole(state, payload) {
      state.singleRole = payload;
    },
    setDefaultPermissions(state, payload) {
      state.defaultPermissions = payload;
    },
  },
  getters: {},
};

export default moduleAdmins;
