const moduleGeneral = {
  state: () => ({
    allReviews: null,
    singleReview: null,
  }),
  actions: {
    async getAllReviews(context, payload) {
      try {
        await this.$axios
          .get(`/reviews`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setAllReviews", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleReview(context, payload) {
      // console.log(payload)
      try {
        await this.$axios
          .get(`/reviews/${payload}`)
          .then((response) => {
            context.commit("setSingleReview", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {

    setAllReviews(state, payload) {
      state.allReviews = payload
    },
    setSingleReview(state, payload) {
      state.singleReview = payload
    },
  },
  getters: {},
};

export default moduleGeneral;




