const moduleGeneral = {
  state: () => ({
    AllSectors: [],
    SingleSector: null,
    allItems: null,
    singleItem: null,
  }),
  actions: {
    async getAllSectors(context, payload) {
      try {
        await this.$axios
          .get(`/sectors`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              "filters[name]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setAllSectors", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleSector(context, payload) {
      console.log(payload);
      try {
        await this.$axios
          .get(`/sectors/${payload}`)
          .then((response) => {
            context.commit("setSingleSector", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getAllItems(context, payload) {
      try {
        await this.$axios
          .get(`/items`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              sector_id: payload.sector_id,
              "filters[search]": payload.search,
            },
          })
          .then((response) => {
            context.commit("setAllItems", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleItem(context, payload) {
      console.log(payload);
      try {
        await this.$axios
          .get(`/items/${payload}`)
          .then((response) => {
            context.commit("setSingleItem", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setAllSectors(state, payload) {
      state.AllSectors = payload;
    },
    setSingleSector(state, payload) {
      state.SingleSector = payload;
    },
    setAllItems(state, payload) {
      state.allItems = payload;
    },
    setSingleItem(state, payload) {
      state.singleItem = payload;
    },
  },
  getters: {},
};

export default moduleGeneral;
