import Vue from "vue";
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import {
  required,
  email,
  min,
  max,
  confirmed,
  min_value,
  max_value,
  image,
  numeric,
  double,
  size,
  digits,
  between,
  required_if,
} from "vee-validate/dist/rules";
import { isCreditCard, isEmail } from "validator";

// function getCurrentLang({app}) {
//   return app.i18n.localeProperties.code
// }
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

const dictionary = {
  en: {
    messages: {
      required: "{_field_} can not be empty",
      numeric: "{_field_} must be Numbers only",
      double: "{_field_} must be Numbers only",
      max: "{_field_} may not be greater than {length} characters",
      min: "{_field_} may not be less than {length} characters",
      email: "Email must be valid",
      confirmed: "Passwords do not match",
      size: "{_field_} max size is {size}",
      upCase: "{_field_} must contains one uppercase character",
      specialChar: "{_field_} must contains one special characters",
      credit_number: "{_field_} not valid ",
      digits: "{_field_} must be {length} numbers ",
      phone_number: "{_field_} is invalid",
      phoneOrEmail: "{_field_} must be a valid phone number or a valid Email",
      between: "{_field_} must be between {min} and {max}",
      min_value: "{_field_} equal or greater than {min}",
      max_value: "{_field_} equal or less than {max}",
      required_if:
        "The {field} field is required when the {target} field is filled.",
    },
  },
  ar: {
    messages: {
      required: "{_field_} لا يمكن ان يكون فارغا",
      numeric: "{_field_} يجب ان يكون ارقام فقط",
      double: "{_field_} يجب ان يكون ارقام فقط",
      max: "{_field_} يجب ان يكون اكبر من {length} حرف",
      min: "{_field_} لا يمكن ان يكون اقل من  {length} حرف",
      email: "البريد الالكتروني غير صحيح",
      confirmed: "يجب ان تتطابق كلمة السر و تأكيد كلمة السر",
      size: "{_field_} اقصى حجم هو  {size}",
      upCase: "{_field_} يجب ان يحتوى على حرف capital على الاقل ",
      specialChar: "{_field_} يجب ان تحتوى على special characters",
      credit_number: "{_field_} ليس صحيحا",
      digits: "{_field_} يجب ان يكون {length} ارقام ",
      phone_number: "{_field_} رقم الهاتف غير صالح",
      phoneOrEmail: "{_field_} يجب ان يكون رقم هاتف صالح او ايميل ",
      between: "{_field_} يجب أن تكون بين {min} و {max}",
      min_value: "{_field_} يجب أن يكون اكبر من أو يساوى {min}",
      max_value: "{_field_} يجب أن يكون أقل من أو يساوى {max} ",
      required_if:
        " {_field_}  لا يمكن ان يكون فارغا عندما يكون  {target} له قيمة",
    },
  },
};

extend("required", required);

extend("email", email);

extend("min", min);

extend("min_value", min_value);

extend("max_value", max_value);

extend("numeric", numeric);

extend("double", double);

extend("image", image);

extend("max", max);

extend("confirmed", confirmed);

extend("size", size);

extend("digits", digits);

extend("between", between);

extend("required_if", required_if);

extend("credit_number", {
  validate: (value) => isCreditCard(value),
});
extend("upCase", {
  validate: (value) => value.match(/[A-Z]/g) !== null,
});
extend("specialChar", {
  validate: (value) => value.match(/[!@#$^&*()_-]/g) !== null,
});

extend("phone_number", {
  validate: (value) => value.match(/^\+(?:[0-9] ?){6,14}[0-9]$/g) !== null,
});

extend("phoneOrEmail", {
  validate: (value) =>
    isEmail(value) || value.match(/^\+(?:[0-9] ?){6,14}[0-9]$/g),
});

// const phoneOrEmailRule = {
//   validate(value, args) {

//     // Custom regex for a phone number
//     // (supplied in the SO post: https://stackoverflow.com/q/50033651/2600825)
//     const MOBILEREG = /^((1[3578][0-9])+\d{8})$/;

//     // Check for either of these to return true
//     return email(value) || MOBILEREG.test(value);
//   }
// };

export default function ({ app }) {
  //Loading languages for Vee
  localize("en", dictionary.en);
  localize("ar", dictionary.ar);

  //Localizing the app when user refresh or access a localized link
  localize(app.i18n.localeProperties.code);

  //Called everytime language change
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    localize(newLocale);
  };
}
// localize( 'ar' , dictionary.ar)
