
import { mapState } from "vuex";
export default {
  name: "EmptyLayout",
  data() {
    return {};
  },
  beforeCreate() {
    if (this.$i18n.localeProperties.rtl == "true") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
};
