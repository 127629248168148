import ar from "vuetify/es5/locale/ar";
export default {
  ...ar,
  hello: "اهلا بالعالم",
  pages: {
    home: "الرئيسية",
    marketSum: "ملخص السوق",
    sectors: "القطاعات",
    distributions: "التوزيعات النقدية",
    subscription: "الاشتراكات",
    blog: "مقالات",
    mohalelTools: "أدوات محلل",
    discover: "مستكشف محلل",
    comparison: "مقارنة",
    comparisons: "مقارنات",
    calendar: "التقويم",
    profits: "نتائج الارباح",
    profile: "الملف الشخصي",
    changPassword: "تغيير كلمة المرور",
    manageSub: "إدارة الاشتراكات",
    settings: "إعدادات الموقع",
    features: "مميزاتنا",
    categories: "التصنيفات",
    about: "عن الشركة",
    contact: "اتصل بنا",
    help: "تحتاج مساعدة ؟ ",
    search: "بحث",
    searchCompany: "ابحث باسم الشركة او الرمز",
    newProducts: "منتجات جديدة",
    login: "تسجيل الدخول",
    signup: "تسجيل حساب ",
    ourVision: "رؤيتنا",
    terms: "الشروط و الأحكام",
    privacy: "سياسية الخصوصية",
    dashboard: "لوحة التحكم ",
    verification: "كود التأكيد",
    accountManagement: "إدارة الحساب",
    subscriptionManagement: "إدارة الاشتراكات",
    siteSettings: "إعدادات الموقع",
    personalInfo: "البيانات الشخصية",
    changeProfilePicture: "تغيير الصورة الشخصية",
    usersManagement: "ادارة المستخدمين",
    users: "العملاء",
    subscriptions: "الاشتراكات",
    wallets: "محافظ العملاء",
    CompanyManagement: "ادارة بيانات الشركات و السوق",
    companies: "الشركات",
    companiesSummary: "ملخص السوق",
    mainMarket: "أسعار السوق الرئيسي(تاسي)",
    levels: "المؤشرات",
    companiesIndicators: "مؤشرات الشركات",
    researchFirms:"بيوت الخبرة",
    distributions: "تاريخ التوزيعات النقدية",
  },
  home: {
    whyMohlel: "لماذا محلل؟",
    subscription_subtitle: "باقات اشتراك متنوعة تناسب أهدافك",
    testimonials: "قالوا عن مُحلل",
    testimonials_subtitle:
      "أكثر من 10,000 عميل اختاروا محلل لمتابعة أسهمهم في السوق",
    consult: "اسأل مستشارينا",
    consult_msg: "موجودون يوميًا للرد على استفساراتك وتساؤلاتك",
    market: {
      dividend: "توزيعات الأرباح",
      courses: "الدورات التدريبية",
      news: "الأخبار",
      QA: "سؤال وجواب",
    },
    mohlel: {
      aboutUs: "من نحن",
      plans: "الباقات",
      terms_conditions: "الشروط والأحكام",
      affiliate_marketing_program: "برنامج التسويق بالعمولة",
    },
  },
  account: {
    profilePictureHint:
      "تأكد من أن الصورة الشخصية التي تريد رفعها هي ملف صورة بصيغة JPG أو PNG و بحجم أقل من 5MB",
    profileWelcomeMsg:
      "أهلاً وسهلاً بك في صفحة تعديل الملف الشخصي. نأمل أن تتمكن من العثور على كل ما تريده هنا وتحسين الملف الشخصي الخاص بك.",
    passwordHintTitle: "تعليمات كلمة المرور",
    passwordHintContent: `يجب أن تكون كلمة المرور الجديدة معقدة وتشمل الأحرف الصغيرة والكبيرة، الأرقام، والحروف الخاصة.</br> أن تحتوي على أكثر من 8 حروف أو أرقام، تجنب الأسماء الشخصية، الأسماء الشعبية، التاريخ الذكي، أو الجزء الثاني من البريد الإلكتروني.`,
    currentPlan: "خطتك الحالية هى: ",
    subscriptionEnd: "ينتهي الاشتراك في ",
  },
  comparisons: {
    compareTillFive: "قارن حتى 5 شركات",
    stateShare: "حالة السهم",
  },
  labels: {
    no: "لا",
    yes: "نعم",
    all: "الكل",
    mohlel: "محلل",
    recommended: "نوصي بها",
    free: "مجانًا",
    SR_month: " ر.س/شهر",
    SR_year: "ر.س/سنة",
    prev: "السابق",
    next: "التالى",
    readMore: "اقرأ المزيد",
    mainMarket: "السوق الرئيسية",
    more: "المزيد",
    moreDetails: "تفاصيل اكثر",
    seeAll: "مشاهدة الكل ",
    send: "ارسال",
    verify: "تحقق",
    viewDetails: "مشاهدة التفاصيل",
    filter: "تنقية",
    sections: "قسم",
    items: "عنصر",
    mailSeller: "مراسلة البائع",
    sendRequest: "ارسال طلب",
    contactSeller: "اتصل بالبائع",
    requestOrders: "ارسل طلب ",
    description: "الوصف",
    specification: "الخصائص",
    verify: "تأكيد",
    or: "أو",
    saveChanges: "حفظ التغييرات",
    monthly: "شهري",
    yearly: "سنوي",
    logout: "تسجيل الخروج",
    followBlog: "متابعة المقالات",
    chooseSector: "اختر القطاع",
    sortBy: "ترتيب حسب",
    choose: "اختر",
    IPOs: "الاكتتابات",
    profits: "نتائج الأرباح",
    cache: "التوزيعات النقدية",
    nextIPOs: "الاكتتابات القادمة",
    prevIPOs: "الاكتتابات السابقة",
    exportExcel: "تصدير EXCEL",
    unsubscribe: "إلغاء الاشتراك",
    upgradePlan: "ترقية الباقة",
    sortByProfits: "ترتيب حسب الاعلى ربحية",
  },
  headers: {
    exploreMohlel: "استكشف محلل",
    subscribe: "اشترك الآن",
    subscriptionPlan: "باقات الاشتراك",
    companyNews: "أخبار الشركات",
    market: "السوق",
    shareIndex: "مؤشر السوق الرئيسية (تاسى)",
    lessProfits: "المكررات الربحية الاقل (PE)",
    highestProfits: "أعلى الشركات ربحية للسهم",
    features: "مميزاتنا",
    suggested: "مقترح لك",
    about: "عن الشركة",
    contact: "ابق على تواصل",
    suggestionOrProblem: "اقتراحك او مشكلتك",
    follow: "تابعنا",
    mainLinks: "الصفحات الرئيسية",
    company: "الشركة",
    contactSeller: "اتصل بالبائع",
    requestOrders: "ارسال طلب",
    comparePlans: "قارن بين الخطط المختلفة",
  },
  inputs: {
    name: "الاسم",
    email: "البريد الالكتروني",
    email_or_phone: "البريد الالكتروني او الهاتف",
    message: "الرسالة",
    phone: "رقم الهاتف",
    password: "كلمة المرور",
    confirmPassword: "تأكيد كلمة المرور",
    oldPassword: "كلمة المرور القديمة",
    newPassword: "كلمة المرور الجديدة",
    confirmNewPassword: " تأكيد كلمة المرور الجديدة",
    plan: "باقة",
    verificationCode: "رمز التحقق",
    notes: "اكتب ملاحظاتك",
    truckModel: "نوع الشاحنة",
    truckBrand: "موديل الشاحنة",
    sellerCountry: "بلد البائع",
    phoneInvalid: "رقم الهاتف غير صالح",
    time: "الوقت",
    date: "التاريخ",
    nameOncard: "الاسم على الكارت",
    cardNumber: "رقم الكارت",
    expire: "تاريخ الانتهاء",
    cvv: "الرقم السري",
    address: "Address",
  },
  blocksMessages: {
    contactSeller: "يمكنك الاتصال المباشر مع البائع",
    visitWebsite: "أو تصفح موقع البائع ",
    requestOrders:
      "فضلا أكمل البيانات التالية و سوف نقوم بالرد عليكم في أقرب وقت",
    verification: "ادخل كود التأكيد الذي تم ارساله لهاتفك",
    noAccount: `ليس لديك عضوية ؟ `,
    creatAccount: "انشاء احساب",
    forgotPassword: "نسيت كلمة المرور ؟ ",
    haveAccount: "لديك عضوية بالفعل؟",
  },
  copyRights: {
    reserved: "جميع الحقوق محفوظة @",
    websiteName: "محلل 2020",
  },
  auth: {
    hello: "أهلًا بك،",
    startAccount: "ابدأ حسابك",
    free: "مجانًا ",
    now: "الآن",
    orChoose: "أو اختر أي من",
    plans: "باقات",
    mohalelPlans: "التحليل الاحترافي من محلل",
    onlyForAccounts: "هذه الخاصية متاحة للمسجلين فقط ",
    loginByGoogle: "تسجيل الدخول باستخدام جوجل",
    helloAgain: "أهلًا بك مرة أخرى",
    loginTo: " سجل دخولك",
    manageYourAccount: "لإدارة حسابك",
    didYouReceiveMessage: "هل استلمت رمز التحقق؟",
    SendVerAgain: "ارسال مرة اخرى",
  },
  sectors: {
    companyInfo: "اسم ورمز الشركة",
    closingPrice: "سعر الاغلاق",
    priceMarket: "القيمة السوقية",
    capitalMoney: "رأس المال لمدفوع",
    multiplierBookValue: "مضاعف القيمة الدفترية",
    earningPerShare: "ربحية السهم",
    profitabilityBis: "مكرر الربحية",
    nextDistribution: "تاريخ التوزيع القادم",
    lastDistribution: "تاريخ اخر توزيع",
    annualDistributionRatio: "نسبة التوزيع السنوي",
  },
  calendar: {
    mainCalendar: "التقويم الرئيسي",
  },
  invoice: {
    number: "رقم المعاملة",
    user_name: "الاسم",
    user_email: "البريد الالكتروني",
    user_phone: "الهاتف",
    date: "تاريخ المعاملة",
    plan: "الخطة",
    type: "نوع المعاملة",
    price: "السعر قبل الضريبة",
    tax: "الضريبة",
    price_tax: "السعر بعد الضريبة",
    total: "الاجمالي",
    mohlel_company: "شركة محلل  ",
    mohlel_numb: "سجل تجاري رقم :  ",
    mohlel_vat: "الرقم الضريبي :  ",
    print: "طباعة الفاتورة",
    price_tax_after_discount: "السعر بعد الخصم",
    price_tax_before_discount: "السعر قبل الخصم",
  },
  features: {
    featurePictureHint:
      "تأكد من أن صور  الميزة/الخاصية التي تريد رفعها هي ملف صورة بصيغة JPG أو PNG و بحجم أقل من 5MB",
    featureVideoHint:
      "تأكد من أن فيديو  الميزة/الخاصية التي تريد رفعه هو ملف فيديو بصيغة mp4 و بحجم أقل من 5MB",
  },
};
