
export default function ({ $axios, app , env  }) {
  // console.log(app)
  app.vuetify.framework.lang.current = app.i18n.localeProperties.code;
    if(app.i18n.localeProperties.rtl == 'true'){
      app.vuetify.framework.rtl = true
    }else{
      app.vuetify.framework.rtl = false
    }
    app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
      if(app.i18n.localeProperties.rtl == 'true'){
        app.vuetify.framework.rtl = true
      }else{
        app.vuetify.framework.rtl = false
      }
    }
}
