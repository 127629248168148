const moduleGeneral = {
  state: () => ({
    companyProfile: null,
    companyGeneralData: null,
    Companies: [],
    financialLists: null,
    marketPrices: null,
    singleMarketPrice: null,
    marketSummary: null,
    singleMarketSummary: null,
    item: {},
  }),
  actions: {
    async getAllCompanies(context, payload) {
      try {
        await this.$axios
          .get(`/companies`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              "filters[name]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setCompanies", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCompanyProfile(context, payload) {
      try {
        await this.$axios
          .get(`/companies/${payload.slug}`)
          .then((response) => {
            context.commit("setCompanyProfile", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getFinancialLists(context, payload) {
      try {
        await this.$axios
          .get(`/companies/${payload.slug}/financial-lists`, {
            params: {
              file_type: payload.file_type,
              yearly_type: payload.yearly_type,
            },
          })
          .then((response) => {
            context.commit("setFinancialLists", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getItem(context, payload) {
      try {
        await this.$axios
          .get(`/companies/items/get-values`, {
            params: {
              file_type: payload.file_type,
              type: payload.type,
              slug: payload.slug,
              code: payload.code,
            },
          })
          .then((response) => {
            context.commit("setItem", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getMarketPrices(context, payload) {
      try {
        await this.$axios
          .get(`/market-prices`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              "filters[search]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setMarketPrices", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleMarketPrice(context, payload) {
      try {
        await this.$axios
          .get(`/market-prices/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleMarketPrice", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getMarketSummary(context, payload) {
      try {
        await this.$axios
          .get(`/market-summary`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              "filters[search]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setMarketSummary", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleMarketSummary(context, payload) {
      try {
        await this.$axios
          .get(`/market-summary/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleMarketSummary", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setCompanies(state, payload) {
      state.Companies = payload;
    },
    setCompanyProfile(state, payload) {
      state.companyProfile = payload;
    },
    setCompanyGeneralData(state, payload) {
      state.companyGeneralData = payload;
    },
    setFinancialLists(state, payload) {
      state.financialLists = payload;
    },
    setItem(state, payload) {
      state.item = payload;
    },
    setMarketPrices(state, payload) {
      state.marketPrices = payload;
    },
    setSingleMarketPrice(state, payload) {
      state.singleMarketPrice = payload;
    },
    setMarketSummary(state, payload) {
      state.marketSummary = payload;
    },
    setSingleMarketSummary(state, payload) {
      state.singleMarketSummary = payload;
    },
  },
  getters: {},
};

export default moduleGeneral;
