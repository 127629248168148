const moduleAdmins = {
  state: () => ({
    AllNews: [],
    SingleNews: null,
  }),
  actions: {
    async getAllNews(context, payload) {
      try {
        await this.$axios
          .get(`/news`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
            },
          })
          .then((response) => {
            context.commit("setAllNews", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleNews(context, payload) {
      try {
        await this.$axios
          .get(`/news/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleNews", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setAllNews(state, payload) {
      state.AllNews = payload;
    },
    setSingleNews(state, payload) {
      state.SingleNews = payload;
    },
  },
  getters: {},
};

export default moduleAdmins;
