const moduleCoupons = {
  state: () => ({
    AllCoupons: [],
    SingleCoupon: null,
    CouponStatistics: null,
    CouponUsers: null,
  }),
  actions: {
    async getAllCoupons(context, payload) {
      try {
        await this.$axios
          .get(`/coupons`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              ...payload.filterby
            },
          })
          .then((response) => {
            context.commit("setAllCoupons", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleCoupon(context, payload) {
      try {
        await this.$axios
          .get(`/coupons/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleCoupon", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCouponStatistics(context, payload) {
      try {
        await this.$axios
          .get(`/coupons/${payload.slug}/statistics`)
          .then((response) => {
            context.commit("setCouponStatistics", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCouponUsers(context, payload) {
      try {
        await this.$axios
          .get(`/coupons/${payload.slug}/users`,{
            params: {
              per_page: payload.perPage,
              page: payload.page,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setCouponUsers", response.data.data);
          })
          .catch((_error) => {
            throw _error.message;
          });
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    setAllCoupons(state, payload) {
      state.AllCoupons = payload;
    },
    setSingleCoupon(state, payload) {
      state.SingleCoupon = payload;
    },
    setCouponStatistics(state, payload) {
      state.CouponStatistics = payload;
    },
    setCouponUsers(state, payload) {
      state.CouponUsers = payload;
    },
  },
  getters: {},
};

export default moduleCoupons;
