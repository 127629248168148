
export default {
  name: "DashboardLayout",
  middleware: "auth",
  data() {
    return {
      mini: true,
    };
  },
  computed: {
    extraClass() {
      let className = null;
      if (
        this.$route.name == "index___ar" ||
        this.$route.name == "index___en"
      ) {
        className = null;
      } else {
        className = "innperPages";
      }
      return className;
    },
  },
  beforeCreate() {
    if (this.$i18n.localeProperties.rtl == "true") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
  methods: {
    sidebarChange(state) {
      this.mini = state;
      // console.log(state)
    },
  },
};
