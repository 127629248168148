const moduleGeneral = {
  state: () => ({
    indicators: null,
    SingleIndicator: null,
  }),
  actions: {
    async getAllIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/indicators`, {
            params: {
              sector_id: payload.sectorId,
              indicator_type: payload.indicatorType,
              per_page: payload.perPage,
              page: payload.page,
              "filters[search]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            console.log(response.data.data);
            context.commit("setIndicators", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleIndicator(context, payload) {
      // console.log(payload)
      try {
        await this.$axios
          .get(`/indicators/${payload.id}`, {
            params: {
              indicator_type: payload.indicatorType,
            },
          })
          .then((response) => {
            console.log(response.data.data);
            context.commit("setSingleIndicator", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setIndicators(state, payload) {
      state.indicators = payload;
    },
    setSingleIndicator(state, payload) {
      state.SingleIndicator = payload;
    },
  },
  getters: {},
};

export default moduleGeneral;
