// await this.$axios.get('api/home/site-settings' , payload).then((response) => {
//   context.commit("setSiteSettings", response.data);
// })

const moduleMarketSum = {
  state: () => ({
    statics: null,
    EarningPerShare: null
  }),
  actions: {
    async getStatics(context, payload) {
      const server = context.rootState.general.server
      // console.log(payload);
      // console.log(context.rootState.general.server);
      try {
        // console.log(context)
          await this.$axios
          .get(`${server}/market-summary/statistics`)
          .then((response) => {
            context.commit("setStatics", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getEarningPerShare(context, payload) {
      const server = context.rootState.general.server
      // console.log(context.rootState.general.server);
      try {
        // console.log(payload)
          await this.$axios
          .get(`${server}/market-summary/earning-per-share?records_num=${payload.records_num}`)
          .then((response) => {
            context.commit("setEarningPerShare", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }

    },
  },
  mutations: {
    setStatics(state, payload) {
      state.statics = payload;
    },
    setEarningPerShare(state, payload) {
      state.EarningPerShare = payload;
    },
  },
  getters: {},
};

export default moduleMarketSum;
