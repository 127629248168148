
const moduleGeneral = {
  state: () => ({
    allReports: null,
  }),
  actions: {
    async getAllReports(context, payload) {
      try {
        await this.$axios
          .get(`/reports`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              type: payload.type,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setAllReports", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },

  },
  mutations: {

    setAllReports(state, payload) {
      state.allReports = payload
    },
  },
  getters: {},
};

export default moduleGeneral;




