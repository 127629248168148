// plugins/vue-format-global.js
import Vue from "vue";
import moment from "moment";
import { isEmail } from "validator";
var mixin = {
  data() {
    return {
      frozen: false,
      date: null,
    };
  },
  created() {
    // moment.locale("ar");
  },
  methods: {
    counterDown(expireDate) {
      this.frozen = true;
      let now = this.$moment(); //todays date
      let end = this.$moment(expireDate); // another date
      let duration = this.$moment.duration(end.diff(now)); // diff
      this.date = duration;
      console.log(now, end, duration, expireDate, "================");
      const timer = setInterval(() => {
        this.date = this.date.subtract(1, "seconds");
        if (this.$moment.duration(this.date).asSeconds() <= 0) {
          clearInterval(timer);
          this.frozen = false;
        }
      }, 1000);
    },
    handelError(error, type, withCountDown, verification = false) {
      if (error.response.data.status == 400) {
        if (error.response.data.errors) {
          this.$refs.observer.setErrors(error.response.data.errors);
        }
        if (
          type == "phone" &&
          error.response.data.errors &&
          error.response.data.errors.phone
        ) {
          this.$refs.observer.setErrors({
            email: error.response.data.errors.phone,
          });
        }
        if (error.response.data.message) {
          this.$toast.error(error.response.data.message);
        }
        if (withCountDown) {
          this.counterDown(error.response.data.data.expire_at, verification);
        }
      } else {
        this.$toast.error(error.response.data.message);
      }
    },
    // handelCrudError(error) {
    //   this.$toast.error(error.response.data.message);
    //   if (error.response.data.errors) {
    //     this.$refs.observer.setErrors(error.response.data.errors);
    //   }
    // },

    handelCrudError(error) {
      if (error.response.status === 400) {
        if (error.response.data.message) {
          // Display the error message from the 'message' property
          this.$toast.error(error.response.data.message);
        } else if (
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          // Display the error messages from the 'errors' array
          error.response.data.errors.forEach((errorMessage) => {
            this.$toast.error(errorMessage);
          });
        } else {
          this.$toast.error("An error occurred.");
        }
      } else {
        this.$toast.error("An error occurred.");
      }

      if (error.response.data.errors) {
        this.$refs.observer.setErrors(error.response.data.errors);
      }
    },

    handelStatusError(error, type) {
      // Inputs errors
      if (error.response.data.status == 400) {
        if (error.response.data.errors) {
          this.$refs.observer.setErrors(error.response.data.errors);
        }
        if (
          type == "phone" &&
          error.response.data.errors &&
          error.response.data.errors.phone
        ) {
          this.$refs.observer.setErrors({
            email: error.response.data.errors.phone,
          });
        }
        if (error.response.data.message) {
          this.$toast.error(error.response.data.message);
        }
        // (unauth) => 401;  // (banned) => 402; // (invalid_credintials) => 450;
      } else if (
        error.response.data.status == 401 ||
        error.response.data.status == 402 ||
        error.response.data.status == 450
      ) {
        this.$toast.error(error.response.data.message);
        // (unverified) => 406;
      } else if (error.response.data.status == 406) {
        this.$toast.error(error.response.data.message);
        this.$router.push({
          path: "/auth/verification",
        });
        // (frozen) => 403;
      } else if (error.response.data.status == 403) {
        this.$toast.error(error.response.data.message);
        this.counterDown(error.response.data.data.expire_at);
      }
    },
    getUserFinalForm(userForm) {
      let form = Object.assign({}, userForm);
      if (isEmail(userForm.email)) {
        form.type = "email";
      } else {
        form.type = "phone";
        form.phone = form.email;
        form.email = null;
      }
      return form;
    },
    keyExists(obj, key) {
      if (obj[key]) {
        return true;
      }

      return false;
    },
    async uploadHandler(blobInfo, success, failure) {
      // Set up the request data and options
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };

      // Send the request using the axios instance
      await this.$axios
        .post("/handleMedia", formData, options)
        .then((response) => {
          // Return the image URL to TinyMCE
          success(response.data.location);
        })
        .catch((error) => {
          console.error(error);
          failure("Failed to upload image");
        });
    },
  },
};

Vue.mixin(mixin);
