// await this.$axios.get('api/home/site-settings' , payload).then((response) => {
//   context.commit("setSiteSettings", response.data);
// })

const moduleHome = {
  state: () => ({
    homeSections: [],
    singleHomeSection: null,
    homeReviewsSection: null
  }),
  actions: {
    async getHomeSections(context, payload) {
      try {
          await this.$axios
          .get(`/home-page/home-sections`
          )
          .then((response) => {
            context.commit("setHomeSections", response.data.data);
          })
          .catch((_error) => {});

      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleHomeSection(context, payload) {
      try {
        await this.$axios
          .get(`/home-page/${payload.pageLink}`
          )
          .then((response) => {
            context.commit("setSingleHomeSection", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getHomeReviewsSection(context, payload) {
      try {
        await this.$axios
          .get(`/reviews/update/front`
          )
          .then((response) => {
            context.commit("setHomeReviewsSection", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    // /home-page/home-why-mohlel/show


  },
  mutations: {
    setHomeSections(state, payload) {
      state.homeSections = payload;
    },
    setSingleHomeSection(state, payload) {
      state.singleHomeSection = payload;
    },
    setHomeReviewsSection(state, payload) {
      state.homeReviewsSection = payload;
    },
  },
  getters: {},
};

export default moduleHome;
