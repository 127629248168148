const moduleGeneral = {
  state: () => ({
    AllCategories: [],
    MainCategories: [],
    SubCategories: [],
    AllParentCategories: [],
    SingleCategory: null,
  }),
  actions: {
    async getAllCategories(context, payload) {
      try {
        await this.$axios
          .get(`/categories`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              "filters[name]": payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setAllCategories", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getMainCategories(context, payload) {
      try {
        await this.$axios
          .get(`/companies/get/main-categories`, {
            params: {
              ...payload,
            },
          })
          .then((response) => {
            console.log(response.data.data);
            context.commit("setMainCategories", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSubCategories(context, payload) {
      try {
        await this.$axios
          .get(`/companies/get/sub-categories`, {
            params: {
              ...payload,
            },
          })
          .then((response) => {
            context.commit("setSubCategories", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleCategory(context, payload) {
      // console.log(payload)
      try {
        await this.$axios
          .get(`/categories/${payload}`)
          .then((response) => {
            context.commit("setSingleCategory", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getParentCategories(context, payload) {
      // console.log(payload)
      try {
        await this.$axios
          .get(`/categories/data/parent`)
          .then((response) => {
            context.commit("setAllParentCategories", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setAllCategories(state, payload) {
      state.AllCategories = payload;
    },
    setMainCategories(state, payload) {
      state.MainCategories = payload;
    },
    setSubCategories(state, payload) {
      state.SubCategories = payload;
    },
    setSingleCategory(state, payload) {
      state.SingleCategory = payload;
    },
    setAllParentCategories(state, payload) {
      state.AllParentCategories = payload;
    },
  },
  getters: {},
};

export default moduleGeneral;
