const modulePages = {
  state: () => ({
    singlePage: null,
    allPages: []
  }),
  actions: {
    async getAllPages(context, payload) {
      try {
        await this.$axios
          .get(`/pages`)
          .then((response) => {
            context.commit("setAllPages", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSinglePage(context, payload) {
      try {
        await this.$axios
          .get(`/pages/${payload.slug}`)
          .then((response) => {
            context.commit("setSinglePage", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setSinglePage(state, payload) {
      state.singlePage = payload;
    },
    setAllPages(state, payload) {
      state.allPages = payload;
    },
  },
  getters: {},
};

export default modulePages;
