const moduleDashboard = {
  state: () => ({
    statistics: null,
  }),
  actions: {
    async getStatistics(context) {
      try {
        await this.$axios
          .get(`/dashboard-statistics`)
          .then((response) => {
            context.commit("setStatistics", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setStatistics(state, payload) {
      state.statistics = payload;
    },
  },
  getters: {},
};

export default moduleDashboard;
