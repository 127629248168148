const moduleSettings = {
  state: () => ({
    AllSettings: [],
  }),
  actions: {
    async getAllSettings(context) {
      try {
        await this.$axios
          .get(`/settings`)
          .then((response) => {
            context.commit("setSettings", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setSettings(state, payload) {
      state.AllSettings = payload;
    },
  },
  getters: {},
};

export default moduleSettings;
