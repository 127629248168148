const moduleUser = {
  state: () => ({
    plans: [],
    singlePlan: null,
    plansFeatures: [],
    dropdownPlans: [],
  }),
  actions: {
    async getAllPlans(context, payload) {
      try {
        await this.$axios
          .get(`/plans?per_page=20`)
          .then((response) => {
            context.commit("setPlans", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSingleCategory(context, payload) {
      try {
        await this.$axios
          .get(`/plans/${payload}`)
          .then((response) => {
            context.commit("setSinglePlan", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllFeatures(context, payload) {
      try {
        await this.$axios
          .get(`/features`)
          .then((response) => {
            context.commit("setPlansFeatures", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getDropDownPlans(context) {
      try {
        await this.$axios
          .get(`/dropdown-plans`)
          .then((response) => {
            context.commit("setDropdownPlans", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setPlans(state, payload) {
      state.plans = payload;
    },
    setSinglePlan(state, payload) {
      state.singlePlan = payload;
    },
    setPlansFeatures(state, payload) {
      state.plansFeatures = payload;
    },
    setDropdownPlans(state, payload) {
      state.dropdownPlans = payload;
    },
  },
  getters: {},
};

export default moduleUser;
