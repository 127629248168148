export default (ctx ,inject) => {
    let fullpath = ctx.route.fullPath;
    let splitedpath = null;
    let token = null;
    if (fullpath.includes("access_token")) {

      splitedpath = fullpath.split("&");
      token = splitedpath.filter(function (item) {
        return item.includes("access_token");
      });
      token = token[0].replace("access_token=", "");
      ctx.$axios
        .post("/auth/social-login", { access_token: token })
        .then((response) => {
          ctx.$auth.setUserToken(response.data.data.token);
          ctx.$auth.setUser(response.data.data);
          ctx.$toast.success(response.data.data.message);
          ctx.$router.push('/')
          location.reload()
        })
        .catch((error) => {
          console.log(error);
        });
    }
}
