
import { mapState } from "vuex";
export default {
  name: "DefaultLayout",
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: "mdi-apps",
          title: "Welcome",
          to: "/",
        },
        {
          icon: "mdi-chart-bubble",
          title: "Inspire",
          to: "/inspire",
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
  computed: {
    ...mapState("general", ["toggleDrawer"]),
    extraClass() {
      let className = null;
      if (
        this.$route.name == "index___ar" ||
        this.$route.name == "index___en"
      ) {
        className = null;
      } else {
        className = "innperPages";
      }
      return className;
    },
  },
  beforeCreate() {
    if (this.$i18n.localeProperties.rtl == "true") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
};
