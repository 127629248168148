const moduleUser = {
  state: () => ({
    userProfile: null,
    Users: [],
    allEmails: [],
    allPayments: null,
    invoice: null
  }),
  actions: {
    async getAllUsers(context, payload) {
      try {
        await this.$axios
          .get(`/users` , {
            params:{
              per_page: payload.perPage,
              page: payload.page,
              'filters[search]': payload.search,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setUsers", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getUserProfile(context, payload) {
      try {
        await this.$axios
          .get(`/users/${payload.slug}`)
          .then((response) => {
            context.commit("setUserProfile", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllEmails(context, payload) {
      try {
        await this.$axios
          .get(`/system-emails`)
          .then((response) => {
            context.commit("setAllEmails", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllPayments(context, payload) {
      try {
        await this.$axios
          .get(`/transactions` , {
            params:{
              per_page: payload.perPage,
              page: payload.page,
              'filters[search]': payload.search,
              'id': payload.id,
              ...payload.filterby
            }
          })
          .then((response) => {
            context.commit("setAllPayments", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getInvoiceByChrgId(context, payload) {
      try {
        await this.$axios
          .get(`/transactions/${payload.id}`)
          .then((response) => {
            context.commit("setInvoice", response.data.data);
          })
          .catch((_error) => { });
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.Users = payload
    },
    setUserProfile(state, payload) {
      state.userProfile = payload
    },
    setAllEmails(state, payload) {
      state.allEmails = payload
    },
    setAllPayments(state, payload) {
      state.allPayments = payload
    },
    setInvoice(state, payload) {
      state.invoice = payload
    },

  },
  getters: {},
};

export default moduleUser;
