const moduleTables = {
  state: () => ({
    sectors: null,
    profits: null,
    shares: null,
    upcomingIPOS: null,
  }),
  actions: {
    async getSectors(context, payload) {
      const server = context.rootState.general.server
      let filter = ''
      if(payload.filterby != null){
        payload.filterby.forEach((element) => {
          filter += `&${element.key}=${element.value}`
        });
      }
      try {
        await this.$axios
          .get(`${server}/market-summary/companies-by-sector?per_page=${payload.perPage}&page=${payload.page}${ payload.search ? '&search='+payload.search : ''}${filter !== '' ? filter : ''} `)
          .then((response) => {
            context.commit("totalSectors", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getProfits(context, payload) {
      const server = context.rootState.general.server;
      try {
        await this.$axios
          .get(
            `${server}/market-summary/profitability-bis?records_num=${payload.records_num}`
          )
          .then((response) => {
            context.commit("profits", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getShares(context, payload) {
      try {
        await this.$axios
          .get(
            "https://api.instantwebtools.net/v1/passenger?size=" +
              payload.perPage +
              "&page=" +
              payload.page
          )
          .then((response) => {
            context.commit("shares", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getupcomingIPOS(context, payload) {
      try {
        await this.$axios
          .get(
            "https://api.instantwebtools.net/v1/passenger?size=" +
              payload.perPage +
              "&page=" +
              payload.page
          )
          .then((response) => {
            context.commit("upcomingIPOS", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    totalSectors(state, data) {
      return (state.sectors = data);
    },
    profits(state, data) {
      return (state.profits = data);
    },
    shares(state, data) {
      return (state.shares = data);
    },
    upcomingIPOS(state, data) {
      return (state.upcomingIPOS = data);
    },
  },
  getters: {},
};

export default moduleTables;
